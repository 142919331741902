<template>
  <div class="news-detail other-height">
    <div class="page-title">
      <div class="container">
        <div class="con-title con-title-white">
          <span>详情</span>
        </div>
      </div>
    </div>
    <div class="con-item">
      <div class="container">
        <h3>{{ title }}</h3>
        <div class="class-buffer ql-container ql-snow font16">
          <div class="brief-introduction ql-editor">
            <div class="brief-introduction-content" v-html="content"></div>
          </div>
          <div class="l-attahment">
            <span class="xb-attahment" v-if="fileList.length > 0">附件：</span>
            <div
              class="attahment-name"
              v-for="(item, index) in fileList"
              :key="index"
              @click="goDown(item, index)"
            >
              <a-icon type="file" />{{ item.name
              }}<a style="margin-left: 20px">
                <a-icon type="download" />下载
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/services/newsList.js";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  name: "NewsDetail",
  components: {},
  data() {
    return {
      content: "",
      title: "",
      name: "",
      fileList:[]
    };
  },
  created() {
    //查询新闻栏目列表
    this.getContent();
  },
  methods: {
    goDown(item) {
      window.location.href =
        process.env.VUE_APP_API_BASE_URL +
        "/download?fileCode=" +
        item.sysFldFilepath +
        "&downloadName=" +
        item.name;
    },
    getContent() {
      this.fileList = [];
      api.getNoteContentByNoteId(this.$route.params.doi).then((res) => {
        if (res.data.success) {
          this.content = res.data.data.content;
          this.title = res.data.data.title;
          if (
            res.data.map &&
            res.data.map.attachmentList &&
            res.data.map.attachmentList.length > 0
          ) {
            this.fileList = res.data.map.attachmentList;
          }
        } else {
          this.$message.error("查询失败！");
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "../news/NewsDetail.less";
@import "../QuillEditor/quill-editor.less";
</style>
